$(document).ready(function(){
	if(!document.querySelector('.email-common')) return false;
	// #### page : solution slick scripts	
	var $obj = $('.my-Products');
	var $slick = $obj.find('.product-type .items').slick({
		infinite: false,
		listStyle: true, // PJTWAUS-6
		slidesToShow: 4,
		slidesToScroll: 4,
		arrows : true,
		dots: true,
		responsive: [{
			breakpoint: 992,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 3,
				arrows : true
			}
		}, {
			breakpoint: 768,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
				arrows : false
			}
		}],
		prevArrow: carouselOptions.bigAnglePrev, // common.js variable
		nextArrow: carouselOptions.bigAngleNext // common.js variable
	});	
	
	// model search area setting
	var model = {
		// search area
		search: {
			el: document.getElementById('productSearchForm'),
			input: null,
			$anchorList: null,
			myProduct: document.getElementById('myProductList'),
			init: function(){
				var m = model.search;
				m.input = $(m.el).find('.search-common-input').get(0);
				m.$anchorList = $(m.el).find('ul.list');
			}
		},
		// select area
		select: {
			el: document.getElementById('productSelectForm'),
			cate: document.getElementById('categoryId'),
			subCate: document.getElementById('subCateId'),
			model: document.getElementById('modelNumber_sel'),
			init: function(){
				var m = model.select;

				$(m.cate).drawAjaxOptions({
					setTarget: m.subCate,
					empty: m.empty,
					notEmpty: m.notEmpty
				});
				$(m.subCate).drawAjaxOptions({
					setTarget: m.model
				});
			},
			empty: function(){
				var m = model.select;
				var _this = this;
				/*
				m.subCate.removeAttribute('disabled');
				$(m.subCate).append("<option value='"+xssfilter($(m.cate).val())+"' selected></option>")
					.addClass('hidden').trigger('change');
				*/
				//m.subCate.removeAttribute('disabled');
				//$(m.subCate).trigger('change').attr('disabled','disabled').trigger('chosen:updated');

				if(m.cate.selectedIndex != 0 && m.cate.value != '') {
					m.subCate.removeAttribute('disabled');
					$(m.subCate).append("<option value='"+xssfilter($(m.cate).val())+"' selected></option>")
						.addClass('hidden').trigger('change');
				}
			},
			notEmpty: function(){
				var m = model.select;
				$(m.subCate).removeClass('hidden');
			}
		},
		summary: document.getElementById('modelSummary'),
		init: function(){
			// search layer
			model.search.init();
			// select area
			model.select.init();

			model.addEvent();
		},
		pasteModelInfo: function(num, url, param){
			form.modelNum.value = num;
			var sum = model.summary;

			var $modal = $('#modal_psp');
			$('body').trigger('ajaxLoadBefore');
			ajax.call(url, param, 'json', function(data){
				$('body').trigger('ajaxLoadEnd');
				$(sum).find('img').get('0').src = data.imageUrl;
				var $anchor = $(sum).find('a');
				$anchor.get('0').href = data.link;
				if(data.link || data.link == '') {
					if (data.link) {
						$modal.find('.modal-footer a').attr('href', data.link);
						$modal.modal('show');
					}
					$anchor.addClass('disabled');
				}else {
					$anchor.removeClass('disabled');
				}
				
				$anchor.html(data.modelName);
				document.getElementById('search-keyword').value = data.modelName;

				$(sum).find('.labo').html(data.warrantyInfoLabor);
				$(sum).find('.parts').html(data.warrantyInfoParts);
				bindImgError();

				// set visible model summary box
				$(sum).show().siblings().hide();
			});
		},
		addEvent: function(){
			model.search.$anchorList.on({
				click: function(e){
					e.preventDefault();
					var _this = e.currentTarget,
					val = _this.getAttribute('href'),
					param = model.search.el.getAttribute('data-param')+ "=" + val;
					model.search.input.value = val;
					$(model.search.el).find('.search-layer').removeClass('active'); // close search layer
					model.pasteModelInfo(val, model.search.el.action, param);

					$(_this).closest('.field-block').removeClass('error');
				}
			}, 'a');
			$(model.search.myProduct).on({
				click: function(e){
					e.preventDefault();
					var _this = e.currentTarget,
					val = _this.getAttribute('href'),
					param = model.search.el.getAttribute('data-param')+ "=" + val;

					model.search.input.value = val;
					model.pasteModelInfo(val, model.search.el.action, param);
				}
			}, 'a');
			$(model.select.cate).on({
				'change.base': function(){
					$(model.select.model).find('option:not(:first-child)').remove();
					$(model.select.model).attr('disabled','disabled').trigger('chosen:updated');
				}
			});
			$(model.select.model).on({
				change: function(){
					var param = xssfilter($(model.select.el).serialize());
					var url = model.select.el.action;
					var val = $(model.select.model).val();
					var modelNumVis = document.getElementById('search-keyword');
					model.pasteModelInfo(val, url, param);
					modelNumVis.value = val;
				}
			});

			$(model.search.el).on({
				focus: function(e){
					$(e.delegateTarget).addClass('focused');
					$(model.select.el).removeClass('focused').find('.error').removeClass('error');
					$(model.select.el).find('select').removeAttr('required');
					$(model.search.input).attr('required','required');
				}
			}, 'input');
			$(model.select.el).on({
				focus: function(e){
					$(e.delegateTarget).addClass('focused');
					$(e.delegateTarget).find('select').attr('required','required');
					$(model.search.el).removeClass('focused').find('.error').removeClass('error');
					$(model.search.input).removeAttr('required');
				}
			}, 'select, input');
			$(model.summary.querySelector('.delete')).on({
				click: function(){
					$(model.summary).hide().siblings().show();
					document.getElementById('productSearchForm').reset();
					document.getElementById('productSelectForm').reset();
					document.getElementById('modelNumber_hidden').value = '';

					$('#subCateId').attr('disabled','disabled');
					$('#modelNumber_sel').attr('disabled','disabled');
					$('#productSelectForm select').trigger('chosen:updated');
				}
			});
		}
	};

	// form validation
	var form = {
		el: document.getElementById('emailSendingForm'),
		submitBtn: document.querySelector('.in-step-cluster button'),
		modelNum: document.getElementById('modelNumber_hidden'),
		modelNumVis: document.getElementById('search-keyword'),
		emailForm: document.querySelector('form[action="/us/support/emailResult"]'),
		typeSel: document.getElementById('typeOfInquiry'),
		typeNum: document.getElementById('typeOfInquiry_hidden'),
		file: document.getElementById('fileupload'),
		textarea: document.getElementById('aboutMessage'),
		radioSelect: document.getElementById('ceo-email-form-select'), // LGEUS-349  20200422 add
		phoneNumber: document.getElementById('aboutPhoneNumber'), //LGEUS-1245
		init: function(){
			// textarea 글자수 체크
			checkTextLength($(form.textarea), $(form.textarea).siblings('.left-text').find('.count'));
			form.addEvent();

			// 브라우저 back버튼 눌러서 돌아왔을 때 inquiry type 체크하여 데이터 있으면 초기화
			var firstValue=$('#typeOfInquiry').val();
			if(firstValue && firstValue != '') {
				//form.el.typeOfInquiry.value = firstValue;
				$('#typeOfInquiry').val('').trigger('chosen:updated');
			}
			// LGEUS-349  20200422 add
			if ( form.radioSelect && form.el ) form.radioEvent();
		},
		/* LGEUS-349  20200422 add */
		radioEvent : function(){
			var $radio = $(form.radioSelect).find('button');
			$radio.on('click', function(){
				var $this = $(this);
				setActiveChange($this);
			});
			$radio.on('keydown', function (e) {
				if ((e.which === 39 || e.which === 40)) {
					e.preventDefault();
					var $this = $(this);
					if ( $this.is(':last-child') ){
						$this = $this.parent().find('button:first');
					} else {
						$this = $this.next();
					}
					setActiveChange($this);
					$this.focus();
				}
				if ((e.which === 37 || e.which === 38)) {
					e.preventDefault();
					var $this = $(this);
					if ( $this.is(':first-child') ){
						$this = $this.parent().find('button:last');
					} else {
						$this = $this.prev();
					}
					setActiveChange($this);
					$this.focus();
				}
			});
			function setActiveChange($this){
				$this.addClass('btn-secondary').removeClass('btn-outline-secondary').attr({ 'aria-checked': true, 'tabindex' : "0" })
					.siblings('button').addClass('btn-outline-secondary').removeClass('btn-secondary').attr({ 'aria-checked': false, 'tabindex' : "-1" });
				$(form.el).removeClass('d-none');
				// $(form.el).removeClass('d-none').find('input, textarea').val('').prop('checked', false)
				// 	.end().find('.delivery-part .delete').trigger('click');
				// var $fileInput = $(form.el).find('.delivery-part');
				// if( $fileInput.is('.attached') ) {
				// 	$fileInput.find('.delete').trigger('click');
				// }
			}
		},
		/* // LGEUS-349  20200422 add */
		beforeSubmit: function(e){
			e.preventDefault();
			// whole form validation;
			if(!form.el.typeOfInquiry.value || form.el.typeOfInquiry.value == '') {
				window.scrollTo(0, $('.step-in-form').offset().top);
			}
			if(form.emailForm) {
				if(form.modelNum.value != form.modelNumVis.value) {
					window.scrollTo(0, $('#search-keyword').offset().top);
					$('.find-model-complex .search-area .field-block').addClass('error');
					return;
				}
				else if(form.modelNum.value == form.modelNumVis.value  && !$('.find-model-complex em.require')) {
					$('.find-model-complex .search-area .field-block').removeClass('error');
				}
			}
			if($(form.el).checkValidation({trigger:true})) {
				grecaptcha.enterprise.execute($("#sitKey").val(), {action: 'emailSending'}).then(function(token) {
					$('#reCaptchaToken').val(token);
				
					if($(form.file).val()!='') form.validateFile($(form.file));
					//console.log(!$(form.file).closest('.field-block').hasClass('error'), captcha.validation());
					if(!$(form.file).closest('.field-block').hasClass('error') && $('#reCaptchaToken').val() != null) {
	
						// 2019-07-13 submit 대신 ajax 방식으로 변경
						var url = $(form.el).attr('data-action');
						if(!url) {
							form.el.submit();
						} else {
							var params = new FormData();
							var curParams = $(form.el).serializeArray();
	
							for (var i = 0; i < curParams.length; i++) {
								fieldValue = form.el[curParams[i].name].value;
	
								if(form.el[curParams[i].name].length > 0) {
									var options = form.el[curParams[i].name];
										fieldValue = [];
									if(options.type !== undefined) {
										if(options.type.indexOf('select') >= 0) {
											fieldValue = options.options[options.selectedIndex].value;
											// console.log(fieldValue);
										}
									}else {
										for (var optionIdx = 0; optionIdx < options.length; optionIdx++) {
											var currentOpt = options[optionIdx];
											if(currentOpt.checked == true) {
												fieldValue += currentOpt.value;
											}
										}
									}
									
								}
								params.append(curParams[i].name, fieldValue);
							}
	
							//var params = new FormData($(form.el)[0]);
							$files = $(form.el).find('input:file');
							if($files.length > 0) {
								for (var j = 0; j < $files.length; j++) {
										var _file = $files.eq(j).get(0);
										if(_file.files[0]) {
											// console.log(_file.name, _file.files[0]);
											params.append(_file.name, _file.files[0], _file.files[0].name);
										}
									}
							}
	
							//console.log(params);
							var action = $(form.el).attr('action');
							ajax.noCacheCall(url, params, 'json', function(data){
								//$('body').append('<div style="width:100%;padding:30px;">'+JSON.stringify(data)+'</div>');
								if(data.result == false){
									var $error = $('#step_submit_error');
									if(data.errorMessage && data.errorMessage != '') {
										$error.find('.content-paragraph').text(data.errorMessage);
									}else {
										$error.find('.content-paragraph').text($error.attr('data-default-msg'));
									}
									$error.modal('show');
								} else {
									var submitData = JSON.stringify(data.submitData);
									var tempForm = '<form id="tempEmailForm" action="'+action+'" method="'+commonSendType+'"><input type="hidden" name="data" value=""></form>';
									$('body').append(tempForm);
									var tmpform = document.getElementById('tempEmailForm');
									tmpform.data.value = submitData;
									tmpform.submit();
								}
							}, commonSendType, 'body');
						}
	
					}else {
						e.preventDefault();
					}
				});
			}
		},
		validateFile: function(obj) {
			// check file size
			var maxSize = obj.data('max'),
				extension = obj.data('extension'), // 허용되는 파일 확장자 목록, |로 구분 되어 있음 
				filesize = 0,
				browser=navigator.appName,
				file = obj.get(),
				ext,
				fileExtension,
				isOk=true,
				$fieldBlock = obj.closest('.field-block'),
				$error = $fieldBlock.find('.error-msg');

				$fieldBlock.removeClass('error');
				$error.hide().find('>span').hide();

			if(maxSize.indexOf('MB')!=-1) {
				maxSize=parseInt(maxSize.split('MB')[0])*1024*1024;
			}
			if(window.FileReader){
				fileSize = obj[0].files[0].size;
				ext = obj[0].files[0].name;
			} else {
				var fileobj = new ActiveXObject("Scripting.FileSystemObject");
				fileSize = fileobj.getFile( file.value ).size;
				ext = obj.val().split('/').pop().split('\\').pop();
			}
			// Check file size
			if(fileSize>maxSize) {
				isOk = false;
				$fieldBlock.addClass('error');
				$error.show().find('>span').hide();
				$error.find('>span.file-size-over').show();
			}

			// Check file extension
			fileExtension = ext.split('.')[ext.split('.').length-1].toLowerCase();
			// console.log(extension, fileExtension);
			if($.inArray(fileExtension, extension.split('|')) == -1) {
				isOk = false;
				$fieldBlock.addClass('error');
				$error.show().find('>span').hide();
				$error.find('>span.not-fit-type').show();
			}

			// result
			//if(!isOk) {
			//	
			//} else {
			//	
			//}
		},
		addEvent: function(){
			//LGEUS-1245 START
			$(form.phoneNumber).on({
				keyup: function(){				    
					 var num = $(this).val().split('-').join('');   //remove dash
					$(this).val(num.match(/\d{3}(?=\d{2,3})|\d+/g).join('-')); 
					if($(this).val.length == 12){
						$(this).parent().removeClass('error');
					}
				}
			});
			//LGEUS-1245 END
			// typeSel selectbox event
			$(form.typeSel).on({
				change: function(){
					form.typeNum.value = $(this).val();
					if(form.emailForm) {
						if(form.typeNum.value == "006" || form.typeNum.value == "010" || form.typeNum.value == "035") {
							$(form.modelNum).removeAttr('required');
							$(".find-model-complex .write-type .require").hide();
							$('.find-model-complex .search-area .field-block .error-msg .required').text("Invalid Model Number.");
						}
						else if($(form.modelNum).prop('required') == false) {
							$(form.modelNum).attr('required', true);
							$('.find-model-complex .search-area .field-block .error-msg .required').text("Model Number is required.");
							$(".find-model-complex .write-type .require").show();
						}
					}
					$(this).parent().removeClass('error');
				}
			});
			// typeOfInquiry is not valid;
			$(form.typeNum).off().on({
				'invalid.required': function(){
					$(form.typeSel).parents('.field-block').addClass('error');
					$(form.typeSel).trigger('chosen:activate');
				},
				'valid.required': function(){
					$(form.typeNum).parents('.field-block').removeClass('error');
				}
			});
			// file attachment validation
			$(form.file).on({
				change: function(e){
					e.preventDefault();
					if(e.currentTarget.files[0]) {
						form.validateFile($(this));
					}
				}
			});
			// modelnumber is not valid;
			$(form.modelNum).off().on({
				'invalid.required': function(){
					if($(model.select.el).is('.focused')) {
						$fieldBlock = $(model.select.el).find('.field-block');
						var $select = $fieldBlock.find('select');

						$(model.select.el).checkValidation();
					}else {
						$fieldBlock = $(model.search.el).find('.field-block');
						var $input = $(model.search.input);

						$input.focus();
						$fieldBlock.addClass('error')
							.find('.error-msg').find('.required').show()
							.siblings().hide();
					}
				},
				'valid.required': function(){
					$(model.select.el).find('.field-block').removeClass('error');
				}
			});
			$(form.el).on({
				submit: form.beforeSubmit
			});
			// $(form.submitBtn).on({
			// 	click: function(){
			// 		$(form.el).submit();
			// 	}
			// });
		}
	};
	/* [LGEUS-349] 20200413 edit */
	if (model.summary){
		model.init();
	}
	form.init();
});